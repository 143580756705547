import React, { useEffect, useState, useRef } from "react";
import Logo from "../assets/farmaze_logo.png";
import Notification from "../assets/icons/notification.svg";
import User from "../assets/icons/user.svg";
import { isAuthenticated } from "../Pages/Router/privateRoute";
import { connect } from "react-redux";
import withRouter from "../Pages/Router/withRouter";

const HeaderProps = (props) => {
  const isLogin = isAuthenticated();
  const [value, setValue] = useState("dashboard");

  useEffect(() => {
    let { pathname } = props.router.location;
    let val = pathname.toLowerCase().includes("dashboard")
      ? "dashboard"
      : "myorders";
    setValue(val);
  }, [props]);

  return (
    <div className="w-full sticky-wrapper-header">
      <div className="header py-2">
        <div className="container px-0">
          <div className="row d-flex justify-content-between align-items-center">
            <div className="col">
              <a href="/home">
                <img src={Logo} width={60} alt="Logo" />
              </a>
            </div>
            {isLogin && (
              <div className="col d-flex justify-content-end align-items-center navbar-section">
                <h4>
                  <a
                    className={`${
                      value === "dashboard" ? "active-option" : ""
                    }`}
                    href="/dashboard/0"
                  >
                    Home
                  </a>
                </h4>
                <h4>
                  <a
                    className={`${
                      value === "myorders" ? "active-option" : ""
                    }`}
                    href="/MyOrders"
                  >
                    My Orders
                  </a>
                </h4>
                <button className="button-section">My Profile</button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const MainHeaderProps = (props) => {
  const isLogin = isAuthenticated();
  const [value, setValue] = useState("dashboard");
  const [isProfileMenuOpen, setProfileMenuOpen] = useState(false);
  const profileMenuRef = useRef(null);
  const overlayDivRef = useRef(null);

  useEffect(() => {
    let { pathname } = props.router.location;
    let val = pathname.toLowerCase().includes("dashboard")
      ? "dashboard"
      : "myorders";
    setValue(val);
  }, [props]);

  // Close dropdown if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target) &&
        overlayDivRef.current &&
        !overlayDivRef.current.contains(event.target)
      ) {
        setProfileMenuOpen(false);
        overlayDivRef.current.classList.add("d-none"); // Hide overlay when clicked outside
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileMenuRef]);

  const toggleProfileMenu = () => {
    setProfileMenuOpen(!isProfileMenuOpen);
    if (overlayDivRef.current) {
      overlayDivRef.current.classList.toggle("d-none"); // Show/hide overlay
    }
  };

  const onClickLogout = () => {
    localStorage.clear();
    props.router.navigate("/login");
  };

  return (
    <div className="w-full sticky-wrapper-main-header">
      <div className="header py-2">
        <div className="container px-0">
        <div className="overlaydivsection d-none" ref={overlayDivRef}></div>
          <div className="row d-flex justify-content-between align-items-center">
            <div className="col-8 d-flex align-items-center">
              <div className="col-4">
                <a href="/home">
                  <img src={Logo} width={60} alt="Logo" />
                </a>
              </div>
              <div className="col-8">
                <div>
                  <div className="inputseachsection" style={{ width: "60%" }}>
                    <input
                      className="form-control mr-sm-2"
                      type="search"
                      placeholder="Search..."
                      aria-label="Search"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 d-flex justify-content-end align-items-center helpandreportsection">
              <h4>Help</h4>
              <h4>Report</h4>
              <h4 className="notificationsection">
                <a
                  className={` ${value === "myorders" ? "active-option" : ""}`}
                  href="/MyOrders"
                >
                  <img
                    src={Notification}
                    alt="icon"
                    className="notification headericonsection"
                  ></img>
                </a>
              </h4>

              {/* Profile Icon Section with Dropdown */}
              <h4 className="profilesection">
                <div onClick={toggleProfileMenu} className="profile-icon-wrapper">
                  <img
                    src={User}
                    alt="icon"
                    className="user headericonsection"
                  />
                </div>

                {/* Profile Dropdown Menu */}
                {isProfileMenuOpen && (
                  <div
                    className="profilemenusection"
                    ref={profileMenuRef}
                    style={{
                      position: "absolute",
                      backgroundColor: "#fff",
                      right: "0",
                      top: "60px",
                      padding: "25px",
                      zIndex: 9999,
                      boxShadow: "0px 8px 24px rgba(149, 157, 165, 0.2)",
                      width: "max-content",
                    }}
                  >
                    <div className="profilemenusectionheader d-flex">
                      <div
                        className="profilemenuprofileprofilesection"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          backgroundImage: `url(${User})`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      ></div>
                      <div className="profilemenuprofiledetailssection" style={{ marginLeft: "16px" }}>
                        <h6>Hi</h6>
                        <h3>{props.clientDetails?.name ?? "User Name"}</h3>
                        <h5>{props.clientDetails?.email ?? "user@example.com"}</h5>
                      </div>
                    </div>
                    <hr />
                    <div className="profilelistoptionsection">
                      <div onClick={onClickLogout} className="profilelistoptions d-flex align-items-center">
                        <i className="fa fa-power-off"></i>
                        <h3 className="logout ms-3">Log Out</h3>
                      </div>
                    </div>
                  </div>
                )}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mapStateToProps(state) {
  return {
    // products: state.product.products,
  };
}

const Header = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderProps)
);
const MainHeader = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainHeaderProps)
);

export { Header, MainHeader };