

const API =  {
    BASE_URL:process.env.NODE_ENV === 'development'? "https://backend.api.testing.farmaze.com/": process.env.REACT_APP_BACKEND_API_BASE_URL,
    login:"api/v1/auth/login",
    products:"api/v1/products/",
    adminProducts:"api/v1/admin/products/",
    placeOrder:"api/v1/b2bclients/orders/",
    orderList:"api/v1/b2bclients/orders",
    invoices:"api/v1/b2bclients/invoices",
    productTrends:"api/v1/admin/products/trends",
};

export {API};