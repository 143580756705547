// import service from "service";
import axios from "axios";
import {API} from "../apis"
import service from "./axios_service";
import { toast } from 'react-toastify';

const getProcurements = (type,date,time1, time2) => {
    let url = `api/v1/admin/procurements/?price_type=${type}&date=${date}&start_time=${time1}&end_time=${time2}`;
    return dispatch => {
        service.get(url)
            .then((res) => {
              if(type=="cost"){
                const procurements = {};
                let items=res.data.procurement??{}
    for (const group in items) {
        procurements[group] = items[group].map((product) => ({
        ...product,
        cost_price: 0
      }));
    }
                dispatch({
                    type: "GET_COST_PRIZE",
                    data: procurements,
                });
            }else {
                const procurements = {};
                let items=res.data.procurement??{}
    for (const group in items) {
        procurements[group] = items[group].map((product) => ({
        ...product,
        selling_price: 0
      }));
    }
                dispatch({
                    type: "GET_SELLING_PRIZE",
                    data: procurements,
                });
            }
            })
            .catch((error) => {
                console.log("error",error);
            })
    }
}

const getOrders = (type) => {
    let url = `api/v1/admin/orders/`;
    return dispatch => {
    const token = localStorage.getItem("AdminToken");

        service.get(url, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
              
                dispatch({
                    type: "GET_ORDERS",
                    data: res.data,
                });
            })
            .catch((error) => {
                console.log("error",error);
            })
    }
}


const updateOrderStatus = (payload) => {
    let url = `api/v1/admin/orders/${payload["order_id"]}/status`;
    return dispatch => {
    const token = localStorage.getItem("AdminToken");

        service.put(url,payload, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
               dispatch(getOrders());
            })
            .catch((error) => {
                console.log("error",error);
            })
    }
}


const prepareinvoice = (id) => {
    let url = `api/v1/admin/invoices/${id}/prepare_invoice`;
    return dispatch => {
    const token = localStorage.getItem("AdminToken");

       return service.get(url, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
              
                dispatch({
                    type: "PREPARED_INVOICE",
                    data: res.data,
                });
                return true
            })
            .catch((error) => {
                return false;
                console.log("error",error);
            })
    }
}


const getClients = () => {
    let url = `api/v1/admin/b2bclients/`;
    return dispatch => {
    const token = localStorage.getItem("AdminToken");

       return service.get(url)
            .then((res) => {
              
                dispatch({
                    type: "GET_CLIENTS",
                    data: res.data,
                });
            })
            .catch((error) => {
                return false;
                console.log("error",error);
            })
    }
}


const getListOfInvoices = (id) => {
    // 2024-08-10
    let url = `api/v1/admin/invoices/client/${id}?startDate=&endDate=`;
    return dispatch => {

       return service.get(url)
            .then((res) => {
              
               return res.data??[];
            })
            .catch((error) => {
                return [];
                console.log("error",error);
            })
    }
}


const createClient = (payload) => {
    let url = `api/v1/admin/b2bclients/`;
    return dispatch => {
    const token = localStorage.getItem("AdminToken");

       return service.post(url,payload)
            .then((res) => {
                dispatch(getClients());

              return true;
            })
            .catch((error) => {
                return false;
                console.log("error",error);
            })
    }
}

const updateClient = (payload,id) => {
    let url = `api/v1/admin/b2bclients/${id}`;
    return dispatch => {
    // const token = localStorage.getItem("AdminToken");

       return service.put(url,payload)
            .then((res) => {
                dispatch(getClients());
              return true;
            })
            .catch((error) => {
                return false;
                console.log("error",error);
            })
    }
}


const updatePrice = (payload) => {
    let url = `api/v1/admin/products/update-cost-price`;
    return dispatch => {
    const token = localStorage.getItem("AdminToken");
console.log("level")
       return service.post(url,payload, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
                dispatch(getProcurements("cost"))
                toast.success("Updated cost price successfully")

              return true;
            })
            .catch((error) => {
                return false;
                console.log("error",error);
            })
    }
}


const updateSellingPrice = (payload) => {
    let url = `api/v1/admin/orders/update-selling-price`;
    return dispatch => {
    const token = localStorage.getItem("AdminToken");
console.log("level")
       return service.post(url,payload, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
                dispatch(getProcurements("selling"))
                toast.success("Updated selling price successfully")

              return true;
            })
            .catch((error) => {
                return false;
                console.log("error",error);
            })
    }
}


const approveInvoice = (id,payload) => {
    let url = `api/v1/admin/invoices/${id}/approve_invoice`;
    return dispatch => {
    const token = localStorage.getItem("AdminToken");
console.log("level")
       return service.post(url,payload, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
                // dispatch(getProcurements("selling"))
                toast.success("Invoice approved successfully")

              return true;
            })
            .catch((error) => {
                return false;
                console.log("error",error);
            })
    }
}



const addProduct = (data) => {
    let url = `${API.BASE_URL}api/v1/admin/products/`;
    const token = localStorage.getItem("AdminToken");

    return dispatch => {

       return axios.post(url,data,{
        headers: {
          'Content-Type': 'multipart/form-data',
          "Authorization": `Bearer ${token}`,

        },
      })
            .then((res) => {
                toast.success("Product added successfully")

              return true;
            })
            .catch((error) => {
                toast.success("Product added failed")

                return false;
                console.log("error",error);
            })
    }
}

const updateProduct = (data,id) => {
    let url = `${API.BASE_URL}api/v1/admin/products/${id}`;
    const token = localStorage.getItem("AdminToken");

    return dispatch => {

       return axios.put(url,data,{
        headers: {
          'Content-Type': 'multipart/form-data',
          "Authorization": `Bearer ${token}`,

        },
      })
            .then((res) => {
                toast.success("Product updated successfully")

              return true;
            })
            .catch((error) => {
                toast.success("Product updated failed")

                return false;
                console.log("error",error);
            })
    }
}
const getProducts = () => {
    let url = `${API.products}`;
    return dispatch => {

        service.get(url)
            .then((res) => {
                // res.data.products.map((i)=>{
                //         i["qty"]=0;
                //         return i;
                //     });
                dispatch({
                    type: "GET_PRODUCTS",
                    data: res.data.products,
                });
            })
            .catch((error) => {
            })
    }
}

const getAdminProducts = () => {
    let url = `${API.adminProducts}`;
    return dispatch => {

        service.get(url)
            .then((res) => {
                // res.data.products.map((i)=>{
                //         i["qty"]=0;
                //         return i;
                //     });
                dispatch({
                    type: "GET_ADMIN_PRODUCTS",
                    data: res.data.products,
                });
            })
            .catch((error) => {
            })
    }
}

const getProductsTrends = () => {
    let url = `${API.productTrends}`;
    return dispatch => {
        service.get(url)
            .then((res) => {
                dispatch({
                    type: "GET_PRODUCTS_TRENDS",
                    data: res.data,
                });
            })
            .catch((error) => {
            })
    }
}

const updatePassword = (payload) => {
    let url = `api/v1/auth/password`;
    return dispatch => {

       return service.put(url,payload)
            .then((res) => {
                toast.success(res.data?.message??"")
            })
            .catch((error) => {
                toast.error("Password update failed")
            })
    }
}
export {getProcurements,getOrders,updateOrderStatus,prepareinvoice,getClients,createClient,updatePrice,updateSellingPrice,approveInvoice,addProduct,getProducts,getAdminProducts,getProductsTrends,updateClient,getListOfInvoices,updatePassword,updateProduct};